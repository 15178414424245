import React, { useState } from 'react'
import {Container, Grid} from "@mui/material";
import SEO from "../../components/seo/seo";
import Text from "../../components/data-display/text";
import {useTheme} from "@mui/system";
import SupportHero from "../../images/support_hero.jpg";
import SupportSearchBar from "../../components/support-components/support-search-bar";
import {graphql, Link, useStaticQuery} from "gatsby";
import {Play} from "react-feather";
//import ReviewCard from "../../components/home/reviews/review-card";
import SydetaskerVideo from "../../images/sydetasker.mp4";
import {FiArrowRightCircle} from "react-icons/fi";
import ReactPlayer from "react-player";
import ThumbnailImage from "../../images/thumbnail.jpg";
//import RichTextEditor from "../../components/support-components/rich-text-editor";
//import {Node} from "slate";
//import PrimaryButton from "../../components/buttons/primary-button";
//import Article from "../../components/support-components/article";
import InlineLink from "../../components/data-display/inline-link";
import {environment} from "../../environments/quickTaskEnvironemt";
import QuickTaskVideo from "../../quickTaskAssets/Video/QuickTask_Home_Page_Video.mp4";
import quickThumbnailImage from "../../quickTaskAssets/Video/QuickTaskHomePageVideo.webp";
import {isQuickTaskProject, title} from "../../utils/constant.urls";

const QuikTaskMainColor = environment.mainColor;
const styles = {
  articleCategory: {
    width: "100%",
    height: "150px",
    background: "white",
    borderRadius: "6px",
    padding: "12px 24px",
    transition: "0.3s",
    transformOrigin: "0 50%",
    boxShadow: "0 2px 14px rgba(100,100,100,.05)",
    cursor: "pointer",
    position: "relative",
    "&:hover": {
      transform: "translate(0,-6px)",
      boxShadow: "0 2px 20px rgba(0,0,0,.2)",
    },
  },
  buttonPrimaryLight: {
    display: "flex",
    alignItems: "center",
    borderRadius: "48px",
    padding: "10px 18px",
    cursor: "pointer",
    transition: "0.3s",
    background: "rgba(255, 255, 255,0.6)",
    border: "1.5px solid rgba(108, 82, 255,0.2)",
    "&:hover": {
      background: "rgba(255, 255, 255,1)",
    },
  },
};

const Support = () => {
  const theme = useTheme();
  const palette_Color = isQuickTaskProject ? QuikTaskMainColor : theme.palette.primary.main;
  const articles = useStaticQuery(graphql`
    {
        poster: allArticles (
            filter: {
              segment: {regex: "/posters/"}
            }
        )
        {
            group(field: category) {
                fieldValue
                totalCount
                edges
                    {
                        node
                            {
                                categorySlug
                            }
                    }
            }
        }
        tasker: allArticles (
            filter: {
              segment: {regex: "/taskers/"}
            }
        )
        {
            group(field: category) {
                fieldValue
                totalCount
                edges
                    {
                        node
                            {
                                categorySlug
                            }
                    }
            }
        }
    }`);

  const [type, setType] = useState<string>("posters");
  // const [value, setValue] = useState<Node[]>([{type: 'paragraph', children: [{ text: '' }]}]);
  const [video, setVideo] = React.useState(false);

  const getData = (context) => {
    switch (context) {
      case "Payments & Withdrawals":
        return {
          background: "#015b4a",
          color: "white",
          image: null,
        };
      case "Offers & Pricing":
        return {
          background: "#73418F",
          color: "white",
          image: null,
        };
      case "Account & Profile":
        return {
          background: "#323864",
          color: "white",
          image: null,
        };
      case "Understanding Sydetasker":
        return {
          background: "#5d69e3",
          color: "white",
          image: null,
        };
      case "Post a Task":
        return {
          background: "#069378",
          color: "white",
          image: null,
        };
      case "Trust & Safety":
        return {
          background: "#b25ec0",
          color: "white",
          image: null,
        };
      default:
        return {
          background: "white",
          color: "black",
          image: null,
        };
    }
  };

  return (
    <>
      <SEO
        description={
          "What do you need help with? Find relevant articles for Posters & Taskers to get help with issues."
        }
        title={"We're here to help"}
      />
      <div
        style={{
          width: "100%",
          background: `url(${SupportHero}) center 20% / cover`,
          height: "50vh",
        }}
      >
        <div style={{height: "180px"}} />
        <Container maxWidth={"lg"}>
          <div style={{display: "flex", flexDirection: "column"}}>
            <Text variant={"h1"} black style={{color: "white", textAlign: "center"}}>
              {"We're here to help"}
            </Text>
            <div style={{height: "12px"}} />
            <div style={{display: "flex", justifyContent: "center"}}>
              <SupportSearchBar />
            </div>
          </div>
        </Container>
      </div>
      <div
        style={{
          height: "64px",
          display: "flex",
          justifyContent: "center",
          borderBottom: "1px solid rgb(240,240,240)",
          background: "white",
        }}
      >
        <div
          style={{
            cursor: "pointer",
            height: "100%",
            display: "flex",
            alignItems: "center",
            borderBottom: `2px solid ${type === "posters" ? palette_Color : "transparent"}`,
            padding: "0 16px",
          }}
          onClick={() => {
            setType("posters");
          }}
        >
          <Text
            variant={"body1"}
            medium
            style={{color: type === "posters" ? palette_Color : "grey"}}
          >
            For Posters
          </Text>
        </div>
        <div style={{width: "64px"}} />
        <div
          style={{
            cursor: "pointer",
            height: "100%",
            display: "flex",
            alignItems: "center",
            borderBottom: `2px solid ${type === "taskers" ? palette_Color : "transparent"}`,
            padding: "0 16px",
          }}
          onClick={() => {
            setType("taskers");
          }}
        >
          <Text
            variant={"body1"}
            medium
            style={{color: type === "taskers" ? palette_Color : "grey"}}
          >
            For Taskers
          </Text>
        </div>
      </div>
      <Container maxWidth={"lg"}>
        <div style={{padding: "100px 0"}}>
          {
            <Grid container spacing={3}>
              {(articles[type === "posters" ? "poster" : "tasker"]?.group ?? []).map((d) => {
                return (
                  <Grid item xl={4} lg={4} sm={6} xs={11} key={d.fieldValue}>
                    <Link to={`/support/${type}/${d.edges[0].node.categorySlug}`}>
                      <div
                        style={{
                          ...styles.articleCategory as React.CSSProperties,
                          background: getData(d.fieldValue).background,
                        }}
                      >
                        <Text variant={"h6"} medium style={{color: getData(d.fieldValue).color}}>
                          {d.fieldValue}
                        </Text>
                        <Text
                          variant={"body2"}
                          medium
                          style={{color: getData(d.fieldValue).color, opacity: "0.8"}}
                        >
                          {d.totalCount} Articles
                        </Text>
                        <FiArrowRightCircle
                          color={getData(d.fieldValue).color}
                          size={24}
                          style={{position: "absolute", right: "12px", bottom: "12px"}}
                        />
                      </div>
                    </Link>
                  </Grid>
                );
              })}
            </Grid>
          }
        </div>
      </Container>
      <div
        style={{display: "flex", alignItems: "center", flexDirection: "column", marginTop: "8px"}}
      >
        <Text variant={"h3"} black>
          How {title} Works
        </Text>
        <div style={{height: "12px"}} />
        <Text variant={"body1"} style={{maxWidth: "550px", margin: "0 auto", textAlign: "center"}}>
          Watch the video below to understand how {title} can help get your tasks done easily.
        </Text>
      </div>
      <div style={{height: "32px"}} />
      <Grid container direction="row" justifyContent={"center"}>
        <Grid
          item
          md={6}
          sm={6}
          xs={11}
          style={{display: "flex", alignItems: "center", justifyContent: "center"}}
        >
          <InlineLink
            variant={"primary"}
            onClick={() => {
              setVideo(!video);
            }}
            style={{color: palette_Color}}
          >
            <div style={styles.buttonPrimaryLight}>
              <Text
                selectable={false}
                variant={"body2"}
                black
                style={{margin: "0 8px", color: palette_Color}}
              >
                <Play
                  style={{marginRight: "6px", transform: "translateY(2px)", strokeWidth: "3px"}}
                />
                Play Video
              </Text>
            </div>
          </InlineLink>
        </Grid>
      </Grid>
      {video && (
        <Grid container direction="row" justifyContent={"center"}>
          <Grid
            item
            md={6}
            sm={6}
            xs={11}
            style={{display: "flex", alignItems: "center", justifyContent: "center"}}
          >
            <ReactPlayer
              url={isQuickTaskProject ? SydetaskerVideo : QuickTaskVideo}
              playIcon={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                    background: "rgba(0,0,0,0.2)",
                  }}
                >
                  <div
                    style={{
                      background: palette_Color,
                      borderRadius: "50%",
                      width: "64px",
                      height: "64px",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <Play
                      size={28}
                      style={{transform: "translateX(2px)", width: "28px"}}
                      color="white"
                    />
                  </div>
                </div>
              }
              light={isQuickTaskProject ? quickThumbnailImage : ThumbnailImage}
              controls={true}
              playing={true}
            />
          </Grid>
        </Grid>
      )}
      <div style={{height: "60px"}}></div>
      {/* <RichTextEditor value={value} onChange={(newValue) => { setValue(newValue) }} placeholder={"Enter"}/> */}
      {/*<PrimaryButton onClick={() => {*/}
      {/*    firebase.firestore().collection('support_articles').add(new Article({*/}
      {/*        title: "What if I feel unsafe after posting a task?",*/}
      {/*        slug: "what-if-i-feel-unsafe-after-posting-a-task",*/}
      {/*        segment: "posters,taskers",*/}
      {/*        segmentSlug: "posters,taskers",*/}
      {/*        category: "Trust & Safety",*/}
      {/*        categorySlug: "trust-and-safety",*/}
      {/*        section: "Poster Safety",*/}
      {/*        sectionSlug: "poster-safety",*/}
      {/*        body: value,*/}
      {/*        updateAt: new Date()*/}
      {/*    }).toJson())*/}
      {/*}}>*/}
      {/*    Done*/}
      {/*</PrimaryButton>*/}
      <div style={{height: "1px", width: "100%", background: "rgb(220,220,220)"}} />
    </>
  );
};

export default Support