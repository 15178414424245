import React from 'react';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { descriptionInfo, logoJPG } from '../../utils/constant.urls';

const SEO = ({ description, lang = 'en', image, meta = [], keywords = [], title, pathname = '' }) => {
  return (
    <StaticQuery
      query={detailsQuery}
          render={data => {
        const metaDescription = description || data.site.siteMetadata.description || descriptionInfo;
        const metaImage = image || logoJPG;
        const metaUrl = `${data.site.siteMetadata.siteUrl}${pathname}`;
        const pageTitle = title || data.site.siteMetadata.title ;

        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={pageTitle}
            titleTemplate={`%s | ${data.site.siteMetadata.title}`}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: pageTitle,
              },
              {
                property: `og:url`,
                content: metaUrl,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: `og:image`,
                content: metaImage,
              },
              {
                property: `og:image:alt`,
                content: pageTitle,
              },
              {
                property: 'og:image:width',
                content: image?.width || '1200',
              },
              {
                property: 'og:image:height',
                content: image?.height || '630',
              },
              {
                name: `twitter:card`,
                content: `summary_large_image`,
              },
              {
                name: `twitter:title`,
                content: pageTitle,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
              {
                name: `twitter:image`,
                content: metaImage,
              },
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `),
                    }
                  : []
              )
              .concat(meta)}
          />
        );
      }}
    />
  );
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        siteUrl
        description
        author
      }
    }
  }
`;
