import {FiSearch} from "react-icons/fi";
import Text from "../data-display/text";
import Fade from "@material-ui/core/Fade";
import { FiChevronRight, FiMapPin } from 'react-icons/fi';
import FormInput from "../forms/form-input";
import React, {useEffect, useState} from "react";
import MenuWrapper from "../menu-popper/menu-wrapper";
import FormWrapper from "../forms/form-wrapper";
import MenuListItem from "../menu-popper/menu-list-item";
import Article from "./article";
import {graphql, Link, useStaticQuery} from "gatsby";
import stringSimilarity from "string-similarity";

const SupportSearchBar = () => {

    const [query, setQuery] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [articles, setArticles] = useState<Article[]>([]);
    const [results, setResults] = useState<Article[]>([]);
    const [timer, setTimer] = useState<NodeJS.Timeout>();
    const [start, setStart] = useState(false);

    const articlesForSearch = useStaticQuery(graphql`{
            articles: allArticles
        {
            edges
            {
                node
                {
                    title
                    category
                    section
                    segmentSlug
                    categorySlug
                    sectionSlug
                    slug
                }
            }
        }
    }`);

    useEffect(() => {
        if (articlesForSearch) {
            let t = articlesForSearch.articles.edges.map(a => new Article(a.node));
            setArticles(t);
        }
    }, [articlesForSearch])

    const onValueChange = (values: Record<string, string | boolean>) => {

        if (values["search"]) {
            setQuery(values["search"].toString().trim());
            clearTimeout(timer);
            if (values["search"].toString().trim().length > 0) {
                setTimer(setTimeout( () => {
                    setLoading(true);
                    setStart(true);
                    let q = values['search'].toString();
                    let t = [...articles];
                    t.sort((a, b) => {
                        if (stringSimilarity.findBestMatch(a.title, [q]).bestMatch.rating > stringSimilarity.findBestMatch(b.title, [q]).bestMatch.rating)
                            return -1
                        else
                            return 1
                    })
                    // t = t.filter((a) => stringSimilarity.findBestMatch(a.title, [q]).bestMatch.rating > 0.3).slice(0,7)
                    setTimeout(() => {
                        setResults(t)
                        setLoading(false)
                    }, 500)
                }, 500))
            } else {
                setResults([]);
                setLoading(false);
            }
        } else {
            setQuery("");
            setLoading(false);
            setResults([]);
        }
    }

    return (
        <FormWrapper onSubmit={()=>{ return new Promise(() => {}); }} onValueChange={onValueChange} initialValues={{ search: "" }} style={{ maxWidth: "500px" }}>
            <MenuWrapper
                live
                disableToggle
                items={
                    <div style={{ height: "auto" }}>
                        {
                            (query.trim().length === 0) ?
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "80px" }}>
                                    <FiSearch style={{ color: "grey", marginRight: "12px" }} size={14}/>
                                    <Text variant={"caption"}  style={{ color: "grey", marginRight: "12px" }}>Start Typing...</Text>
                                </div>
                                :
                                <div style={{ minHeight: "300px" }}>
                                    <Fade in={!loading && start}>
                                        <div>
                                            {
                                                (results && results.length > 0) ?
                                                    results.slice(0,5).map((r) => {
                                                        return <Link to={`/support/${r.segmentSlug}/${r.categorySlug}/${r.sectionSlug}/${r.slug}`}>
                                                            <MenuListItem key={r.slug}>
                                                                <div style={{ margin: 0, display: "flex", flexDirection: "column" }}>
                                                                    <p style={{ margin: 0, display: "flex", alignItems: "center" }}>
                                                                        <Text component={"span"} variant={"caption"} style={{ color: "grey" }}>{r.category}</Text>
                                                                        <FiChevronRight size={12} color={"grey"} style={{ margin: "0 4px" }}/>
                                                                        <Text component={"span"} variant={"caption"} style={{ color: "grey" }}>{r.section}</Text>
                                                                    </p>
                                                                    <Text component={"span"} variant={"body2"}>{r.title}</Text>
                                                                </div>
                                                            </MenuListItem>
                                                        </Link>
                                                    })
                                                    :
                                                    <MenuListItem>
                                                        <Text variant={"body2"}>We couldn't find anything match your search.</Text>
                                                    </MenuListItem>

                                            }
                                        </div>
                                    </Fade>
                                </div>
                        }
                    </div>
                } style={{ width: "100%" }}>
                <FormInput type={'search'} rootStyle={{ maxWidth: "500px" }} autoComplete={'off'} startAdornment={<FiSearch style={{ marginLeft: "12px" }} size={20}/>} name={"search"} placeholder={"Search a topic or category"} />
            </MenuWrapper>
        </FormWrapper>
    )
}

export default SupportSearchBar;